<template>
  <div>
    <v-dialog
      persistent
      v-model="openDialogOrder"
      width="1200"
      style="z-index: 9999"
    >
      <v-card
        style="padding-top: 25px; padding-bottom: 25px; border-radius: 15px"
      >
        <v-icon
          @click="close"
          color="#000000"
          style="float: right; padding: 5px; margin-right: 5px"
          >mdi-close
        </v-icon>
        <v-card-title
          class="headline justify-center"
          style="text-align: center"
        >
          Nueva orden de compra
        </v-card-title>
        <hr style="height: 1px; background-color: #ffce00; border: none" />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="proyecto"
                  class="text-Poppins"
                  item-text="sName"
                  item-value="sProjectId"
                  @change="getCategoriesbyProyect(proyecto)"
                  :items="itemsProyecto"
                  label="Proyecto"
                  style="margin-bottom: -10px"
                  :disabled="this.itemDetails !== null"
                ></v-select>
                <v-select
                  v-model="categoria"
                  no-data-text="No hay categorias"
                  class="text-Poppins"
                  item-text="sCategoryName"
                  item-value="sCategoryId"
                  :items="itemsCategorias"
                  label="Categoría"
                  style="margin-bottom: -10px"
                  :disabled="proyecto.sName == ''"
                ></v-select>
                <v-select
                  v-model="proveedor"
                  class="text-Poppins"
                  item-text="sCompanyName"
                  item-value="sSupplierId"
                  :items="itemsProveedores"
                  label="Proveedor"
                  style="margin-bottom: -10px"
                  :disabled="categoria == ''"
                ></v-select>
                <v-select
                  v-model="metodoPago"
                  class="text-Poppins"
                  item-text="sName"
                  item-value="sPaymentMethodId"
                  :items="itemsMetodoPago"
                  label="Método de Pago"
                  style="margin-bottom: -10px"
                  :disabled="proveedor == ''"
                ></v-select>
                <v-textarea
                  v-model="comentarios"
                  rows="3"
                  class="texts-inputs"
                  label="Notas al proveedor"
                ></v-textarea>
                <v-textarea
                  v-show="xsWidth"
                  v-model="comentariosInternos"
                  rows="3"
                  maxlength="255"
                  class="texts-inputs mt-3"
                  label="Notas internas"
                >
                  <template slot="label">
                    <span>Notas internas</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                        <v-icon
                          style="cursor: pointer"
                          color="blue"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle</v-icon
                        >

                        <!-- </v-btn> -->
                      </template>
                      <span
                        >Estas notas no serán visibles por los proveedores ni
                        estarán presentes en la cotización generada.
                      </span>
                      <br />
                      <span
                        >Solo modificables en: Borrador y correcciones.</span
                      >
                    </v-tooltip>
                  </template></v-textarea
                >
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <div v-if="filelist.length" class="container-dashed-image">
                  <div style="text-align: center; width: 100%; padding: 20px">
                    <v-icon color="#E2574C" size="100px">mdi-file-pdf</v-icon>
                    <p
                      style="color: #a9a9a9; font-family: 'Poppins', sans-serif"
                    >
                      {{ filelist[0].name }}
                    </p>
                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                      "
                    >
                      <div class="button-wrapper">
                        <v-btn
                          @click="remove()"
                          elevation="0"
                          class="button-eliminar-imagen"
                        >
                          Eliminar
                        </v-btn>
                        
                      </div>
                      <v-btn
                        @click="openPdf"
                        elevation="0"
                        class="button-ver-imagen"
                      >
                        Ver documento
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  style="
                    border: 2px dashed #e0e0e0;
                    width: 100%;
                    height: 230px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <div class="text-center">
                    <v-icon color="#808080">mdi-download</v-icon>
                    <div class="button-wrapper-new">
                      <span class="label"> Agregar archivo </span>

                      <input
                        type="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="previewFiles"
                        ref="file"
                        accept=".pdf"
                      />
                    </div>

                    <label for="assetsFieldHandle" class="block cursor-pointer">
                      <p style="color: #808080; margin-bottom: 0px">
                        o arrastra y suelta la cotización provista por el
                        proveedor.
                      </p>
                    </label>
                  </div>
                </div>
                <!-- <div
                  style="
                    border: 2px dashed #e0e0e0;
                    width: 100%;
                    height: 230px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                  @dragover="dragover"
                  @dragleave="dragleave"
                  @drop="drop"
                >
                  <div class="text-center">
                    <v-icon color="#808080">mdi-download</v-icon>
                    <div class="button-wrapper">
                      <span class="label"> Agregar archivo </span>

                      <input
                        type="file"
                        name="fields[assetsFieldHandle][]"
                        id="assetsFieldHandle"
                        class="w-px h-px opacity-0 overflow-hidden absolute"
                        @change="onChange"
                        ref="file"
                        accept=".pdf"
                      />
                    </div>

                    <label for="assetsFieldHandle" class="block cursor-pointer">
                      <p style="color: #808080; margin-bottom: 0px">
                        o arrastra y suelta la cotización provista por el
                        proveedor.
                      </p>
                    </label>
                    <ul v-if="this.filelist.length" v-cloak>
                      <li
                        style="
                          font-family: 'Poppins', sans-serif;
                          color: #737373;
                          list-style: none;
                        "
                        v-for="(file, index) in filelist"
                        :key="index"
                      >
                        {{ file.name
                        }}<button
                          class="ml-2"
                          style="
                            font-family: 'Poppins', sans-serif;
                            color: #ff6262;
                            text-decoration: underline;
                          "
                          type="button"
                          @click="remove(filelist.indexOf(file))"
                          title="Remove file"
                        >
                          Eliminar archivo
                          <v-icon color="#FF6262" size="13">mdi-delete</v-icon>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div> -->

                <v-textarea
                  v-show="!xsWidth"
                  v-model="comentariosInternos"
                  rows="3"
                  maxlength="255"
                  class="texts-inputs mt-3"
                  label="Notas internas"
                >
                  <template slot="label">
                    <span>Notas internas</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on"> -->
                        <v-icon
                          style="cursor: pointer"
                          color="blue"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >mdi-alert-circle</v-icon
                        >

                        <!-- </v-btn> -->
                      </template>
                      <span
                        >Estas notas no serán visibles por los proveedores ni
                        estarán presentes en la cotización generada.
                      </span>
                      <br />
                      <span
                        >Solo modificables en: Borrador y correcciones.</span
                      >
                    </v-tooltip>
                  </template>
                </v-textarea>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-card style="border-radius: 10px">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">Concepto</th>
                          <th class="text-center">Cantidad</th>
                          <th class="text-center">Unidad de medida</th>
                          <th class="text-center" style="width: 180px">
                            Precio unitario
                          </th>
                          <th class="text-center"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in itemsMateriales"
                          :key="index"
                        >
                          <td>{{ item.sName }}</td>
                          <td>{{ item.dQuantity }}</td>
                          <td>{{ item.unidadMedidaMaterialText }}</td>
                          <td>
                            <input
                              v-model="item.dUnitPrice"
                              :key="index"
                              type="number"
                              class="input-table-materiales"
                              min="0"
                            />
                          </td>
                          <td>
                            <v-icon
                              color="red"
                              @click="deleteItemMaterial(index)"
                              >mdi-close</v-icon
                            >
                          </td>
                        </tr>
                      </tbody>
                      <br />
                      <tfoot>
                        <tr>
                          <td>
                            <v-text-field
                              v-model="nombreMaterial"
                              class="input-nueva-soli text-center"
                              outlined
                              dense
                              placeholder="Concepto"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-text-field
                              v-model="cantidadMaterial"
                              @keypress="isNumber($event)"
                              @change="notCero(cantidadMaterial)"
                              class="input-nueva-soli"
                              outlined
                              dense
                              placeholder="Cantidad"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-select
                              v-model="unidadMedidaMaterial"
                              item-text="sName"
                              item-value="sMeasureUnitId"
                              :items="itemsUnits"
                              class="input-nueva-soli"
                              background-color="#FAFAFA"
                              dense
                              outlined
                              @change="changeUnidadMedidaMaterial"
                              color="primary"
                              placeholder="Unidad de medida"
                            >
                            </v-select>
                          </td>
                          <td>
                            <v-text-field
                              v-model="precioUnitario"
                              @keypress="isNumber($event)"
                              class="input-nueva-soli"
                              outlined
                              dense
                              placeholder="Precio unitario"
                            ></v-text-field>
                          </td>
                          <td>
                            <v-btn
                              style="
                                background-color: #ffce00;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 600;
                                border-radius: 10px;
                                margin-top: -25px;
                              "
                              :disabled="!validateAnadir"
                              @click="setMaterial"
                              >Añadir</v-btn
                            >
                          </td>
                        </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                  <div
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <hr
                      style="
                        width: 97%;
                        border: 1px solid #fafafa;
                        margin-top: 50px;
                      "
                    />
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;
                      padding-right: 20px;
                    "
                  >
                    <p
                      style="
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 600;
                      "
                    >
                      SUBTOTAL
                      <span style="color: #63e3b2; margin-left: 10px"
                        >${{ sumatoriaMateriales() }} MXN</span
                      >
                    </p>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <div class="text-right mt-10">
                  <v-btn
                    @click="close"
                    x-large
                    color="black"
                    width="175"
                    outlined
                    style="
                      height: 40px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                      margin-right: 35px;
                    "
                    >Cancelar
                  </v-btn>
                  <v-btn
                    x-large
                    color="black"
                    width="200"
                    outlined
                    style="
                      height: 40px;
                      font-size: 13px;
                      font-weight: 600;
                      border-radius: 5px;
                      margin-right: 35px;
                    "
                    :loading="lodingButtonBorrador"
                    @click="createNewOrder(2)"
                    >Guardar como borrador
                  </v-btn>
                  <v-btn
                    x-large
                    width="175"
                    color="primary"
                    style="
                      height: 40px;
                      color: black;
                      box-shadow: 0px 20px 40px #00000029;
                      border-radius: 5px;
                      font-size: 13px;
                      font-weight: 600;
                    "
                    :disabled="!validateForm"
                    :loading="lodingButtonAprobacion"
                    @click="createNewOrder(1)"
                    >Enviar para aprobación
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "NewOrderPurchase",
  delimiters: ["${", "}"], // Avoid Twig conflicts
  props: {
    openDialogOrder: {
      type: Boolean,
      default: false,
    },
    resquestMaterials: {
      type: Array,
      default: null,
    },
    itemDetails: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      itemsProyecto: [],
      itemsCategorias: [],
      itemsProveedores: [],
      itemsMetodoPago: [],
      itemsUnits: [],
      itemsMateriales: [],
      proyecto: "",
      categoria: "",
      proveedor: "",
      metodoPago: "",
      comentarios: "",
      comentariosInternos: "",
      nombreMaterial: "",
      cantidadMaterial: "",
      unidadMedidaMaterial: "",
      unidadMedidaMaterialText: "",
      precioUnitario: "",
      lodingButtonBorrador: false,
      lodingButtonAprobacion: false,
      filelist: [], // Store our uploaded files
      xsWidth: false,
      show: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    openDialogOrder: function () {
      if (this.openDialogOrder) {
        this.allData();
      }
    },
  },
  computed: {
    validateForm() {
      return (
        this.proyecto.sName !== "" &&
        this.categoria !== "" &&
        this.proveedor !== "" &&
        this.metodoPago !== "" &&
        this.itemsMateriales.length >= 1
        // this.filelist.length !== 0
      );
    },
    validateAnadir() {
      return (
        this.nombreMaterial !== "" &&
        this.cantidadMaterial !== "" &&
        this.unidadMedidaMaterial !== "" &&
        this.precioUnitario !== ""
      );
    },
  },
  methods: {
    onResize() {
      this.xsWidth = window.innerWidth > 599 ? false : true;
    },
    isNumber(evt) {
      if (
        (evt.which != 8 && evt.which != 1 && evt.which < 46) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    notCero(cantidadMaterial) {
      if (cantidadMaterial !== "") {
        if (cantidadMaterial <= 0) {
          const alert = {
            color: "red",
            text: "Es necesario agregar una cantidad mayor a 0",
          };
          this.$store.commit("toggle_alert", alert);
          this.cantidadMaterial = "";
        }
      }
    },
    close() {
      this.$emit("close");
      this.proyecto = "";
      this.categoria = "";
      this.proveedor = "";
      this.metodoPago = "";
      this.comentarios = "";
      this.comentariosInternos = "";
      this.itemsMateriales = [];
      this.filelist = [];
    },
    // get proyectos
    getProyectos() {
      db.get(`${uri}/api/v1/projects/?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsProyecto = resp.data.projects;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    getMetodoPago() {
      db.get(`${uri}/api/v1/payment_methods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsMetodoPago = resp.data.paymentMethods;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },

    // get categories by proyect
    getCategoriesbyProyect(idProyecto) {
      this.itemsCategorias = [];
      this.categoria = "";
      db.get(`${uri}/api/v1/projects/${idProyecto}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsCategorias = resp.data.project.Categories;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get proyectos
    getProveedores() {
      db.get(`${uri}/api/v1/supplier/?iPageNumber=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsProveedores = resp.data.suppliers;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    // get units
    getUnits() {
      db.get(`${uri}/api/v1/measure_units`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("user-tk"),
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((resp) => {
          this.itemsUnits = resp.data.units;
        })
        .catch((err) => {
          this.bLoading = false;
          const alert = {
            color: "red",
            text: err.response.data.message,
          };
          this.$store.commit("toggle_alert", alert);
        });
    },
    changeUnidadMedidaMaterial(e) {
      var text = this.itemsUnits.filter((val) => val.sMeasureUnitId === e);
      this.unidadMedidaMaterialText = text[0].sName;
    },
    allData() {
      if (this.itemDetails !== null) {
        this.proyecto = {
          sProjectId: this.itemDetails.sProjectId,
          sName: this.itemDetails.sProjectName,
        };
        this.getCategoriesbyProyect(this.proyecto.sProjectId);
      } else {
        this.proyecto = {
          sProjectId: "",
          sName: "",
        };
      }
      this.getProyectos();
      this.getMetodoPago();
      this.getProveedores();
      this.getUnits();
      this.loadMaterials();
    },
    loadMaterials() {
      if (this.resquestMaterials !== null) {
        var arr = this.resquestMaterials.map((e) => {
          return {
            ...e,
            sMaterialName: undefined,
            sName: e.sMaterialName,
            sMeasureUnitName: undefined,
            unidadMedidaMaterialText: e.sMeasureUnitName,
            dQuantity: Number(e.dQuantity),
          };
        });

        arr.forEach((element) => {
          this.itemsMateriales.push(element);
        });
      }
    },
    setMaterial() {
      const arr = {
        sName: this.nombreMaterial,
        dQuantity: this.cantidadMaterial,
        sMeasureUnitId: this.unidadMedidaMaterial,
        unidadMedidaMaterialText: this.unidadMedidaMaterialText,
        dUnitPrice: this.precioUnitario,
      };
      this.itemsMateriales.push(arr);
      this.clearItemsMateriales();
    },
    clearItemsMateriales() {
      this.nombreMaterial = "";
      this.cantidadMaterial = "";
      this.unidadMedidaMaterial = "";
      this.precioUnitario = "";
    },
    deleteItemMaterial(e) {
      this.itemsMateriales.splice(e, 1);
    },
    //1.- enviada
    //2.- borrador
    createNewOrder(status) {
      var idproyecto = "";

      if (this.proyecto.sProjectId == undefined) {
        idproyecto = this.proyecto;
      } else {
        idproyecto = this.proyecto.sProjectId;
      }

      if (status == 2) {
        this.lodingButtonBorrador = true;
      } else {
        this.lodingButtonAprobacion = true;
      }

      /*if (this.filelist.length > 0) {*/
      if (this.itemsMateriales.length !== 0) {
        const payload = {
            sProjectId: idproyecto,
            sComments: this.comentarios,
            sPrivateComments: this.comentariosInternos,
            sSupplierId: this.proveedor,
            sPaymentMethodId: this.metodoPago,
            iPurchaseOrderStatusType: status,
            sCategoryId: this.categoria,
            aOrderMaterials: this.itemsMateriales.map((se) => {
              return {
                dQuantity: se.dQuantity,
                sMeasureUnitId: se.sMeasureUnitId,
                sName: se.sName,
                dUnitPrice: se.dUnitPrice,
              };
            }),
          },
          config = {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("user-tk"),
              contentType: "application/x-www-form-urlencoded;charset=utf-8",
            },
          };
        db.post(`${uri}/api/v1/purchase_order`, payload, config)
          .then((response) => {
            let form = new FormData();
            let file = this.filelist[0];
            form.append("file", file);

            const config2 = {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("user-tk"),
                contentType: "application/pdf",
              },
            };
            if (response.status == 201) {
              db.post(
                `${uri}/api/v1/purchase_order/${response.data.orderMaterial.sPurchaseOrderId}/uploadPDF`,
                form,
                config2
              )
                .then((resp) => {
                  this.itemsMateriales = [];
                  this.proyecto = "";
                  this.proveedor = "";
                  this.metodoPago = "";
                  this.comentarios = "";
                  this.comentariosInternos = "";
                  this.lodingButtonBorrador = false;
                  this.lodingButtonAprobacion = false;
                  const alert = {
                    color: "green",
                    text: resp.data.message,
                  };
                  this.close();
                  this.$store.commit("toggle_alert", alert);
                  this.$store.commit("refresher", true);
                })
                .catch((err) => {
                  this.bLoading = false;
                  this.lodingButtonBorrador = false;
                  this.lodingButtonAprobacion = false;
                  const alert = {
                    color: "red",
                    text: err.response.data.message,
                  };
                  this.$store.commit("toggle_alert", alert);
                });
            }
          })
          .catch((err) => {
            this.bLoading = false;
            this.lodingButtonBorrador = false;
            this.lodingButtonAprobacion = false;
            const alert = {
              color: "red",
              text: err.response.data.message,
            };
            this.$store.commit("toggle_alert", alert);
          });
      } else {
        this.lodingButtonBorrador = false;
        this.lodingButtonAprobacion = false;
        const alert = {
          color: "red",
          text: "La orden de compra debe de tener al menos un material.",
        };
        this.$store.commit("toggle_alert", alert);
      }
    },
    sumatoriaMateriales() {
      var sum = 0;
      for (let i = 0; i < this.itemsMateriales.length; i++) {
        const element = this.itemsMateriales[i];
        sum = sum + element.dQuantity * element.dUnitPrice;
      }
      return this.mask(sum);
    },
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    onChange() {
      this.filelist = [...this.$refs.file.files];
    },
    remove() {
      this.filelist = []
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    previewFiles(event) {
      this.filelist = event.target.files;
    },
    openPdf() {
      if (this.filelist.length > 0) {
        this.maskedFile(this.filelist);
      }
    },
    maskedFile: function (afile) {
      var file = new Blob([afile[0]], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    },
  },
};
</script>

<style>
.input-nueva-soli {
  text-align: center !important;
}

::placeholder {
  text-align: center !important;
}

.input-nueva-soli:placeholder-shown {
  text-align: left !important;
}
</style>
<style
  scoped>
[v-cloak] {
  display: none;
}
.texts-inputs {
  margin-bottom: -10px;
}

.input-nueva-soli .v-text-field__details {
  display: none;
}

.searchTextField {
  margin-top: -28px;
}
.SearchWrapper {
  max-height: 80px;
  padding-left: 10px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 40px transparent !important;
  border-radius: 30px !important;
  width: 100%;
}

.button-wrapper {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper span.label {
  width: 160px;
  position: relative;
  z-index: 0;
  line-height: 26px;
  display: inline-block;
  background: #ff5757;
  cursor: pointer;
  color: #ffffff;
  padding: 5px 25px;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-right: 5px;
  font-family: "Poppins", sans-serif;
}

.button-eliminar-imagen {
  width: 160px;
  height: 36px;
  background-color: #ff5757 !important;
  cursor: pointer;
  color: #4e4000;
  padding: 5px 25px !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0;
}
.button-ver-imagen {
  width: 160px;
  height: 36px;
  background-color: #ffce00 !important;
  cursor: pointer;
  color: #4e4000;
  padding: 5px 25px !important;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  margin-left: 5px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 0;
}
.button-wrapper-new {
  position: relative;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper-new span.label {
  position: relative;
  z-index: 0;
  display: inline-block;
  background: #ffffff;
  cursor: pointer;
  color: #737373;
  padding: 5px 25px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  border: 2px solid #737373;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}
#assetsFieldHandle {
  display: inline-block;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 50px;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.container-dashed-image {
  border: 2px dashed #e0e0e0;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-table-materiales {
  background-color: #fafafa;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  padding: 5px;
}

.input-table-materiales:focus {
  outline: 0;
}
</style>